.main {
    min-height: 44vh;
}

.navbar {
    background-color:#000; /* Fioletowy kolor? */
    /* background-color:yellowgreen; */
    /* background-color:rgb(255, 44, 44); */
    color:#fff;
}

.navbar a {
    color:inherit;
}

.fx-navbar a, .navbar-brand {
    color:inherit !important;
}

.theme-white .svg-icon {
    filter: invert(100%) sepia(0%) saturate(7497%) hue-rotate(7deg) brightness(105%) contrast(101%);
}

.product-item {
    width:350px;
    max-width:100%;
    margin:0 auto;
}

.product-item:nth-child(3n+2) {
    /* margin-left:calc((100% - 1056px) / 2); 906 */
    /* margin-right:calc((100% - 1056px) / 2); */
    
    margin-left:24px;
    margin-right:24px;
}

.products-margin .product-item {
    /* margin-bottom:calc((100% - 1056px) / 2); */
    margin-bottom:24px;
}

.product-images-list {
    list-style-type: none;
    padding:0;
    width: 100%;
    /* height:160px; */
    display:flex;
    overflow-x:auto;
}

.product-images-list img {
    /* height:100%; */
    cursor:pointer;
}

.custom-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar {
    width: 10px;
    height: 10px;
	background-color: #F5F5F5;
}

.custom-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

.products-list-item {
    padding-left:0;
    /* padding-right:1.25rem; */
    width:350px;
    margin-right: 10px;
}

.products-list-item:last-child {
    padding-right:0;
    margin-right: 0;
    /* max-width: calc(25% - 1.25rem); */
}


/* LINK PATH */
.link-path {
    list-style-type:none;
    padding:0;
    margin:0 auto;
    display:flex;
}

.link-path > li {
    padding-right:10px;
    position:relative;
}

.link-path > li:not(:first-child) {
    padding-left:20px;
}

.link-path > li:not(:first-child)::after {
    content: '>';
    display:block;
    position:absolute;
    top:8px;
    left:0px;
}


.category-path-name {
    position:relative;
    display:block;
}

.category-path-name a {
    color:inherit;
    text-decoration: none;
}

.link-path li:hover a {
    background-color:#fff;
}

.category-item {
    position:relative;
}

.category-preview-img {
    display: none;
}

.category-item:hover .category-preview, .category-item:hover .category-preview-img {
    display: block;
}

.category-preview {
    position: absolute;
    top:100%; /* left:0; */
    width:350px;
    background-color:#fff;
    display: none;
    z-index: 1;
}

.fx-list {
    list-style-type:none;
}

.active-product-image {
    position:relative;
}

/* .active-product-image::after {
    content:"";
    position:absolute;
    top:0; left:0;
    width: 100%;
    height: 100%;
} */



/* NOTIFICATIONS */
.notifications-container {
    position:fixed;
    left:30px;
    bottom:30px;
    z-index: 1337;
}

.notification {
    font-size:18px;
    padding:15px;
    background-color: #fff;
    color:#222;
}

.notifications-list li:not(:first-child) {
    margin-top:10px;
}

.btn-icon {
    background-color: transparent;
    border:none;
}

.arrow-icon {
    /* fill:#555; */
    /* filter: invert(52%) sepia(66%) saturate(3134%) hue-rotate(185deg) brightness(96%) contrast(79%); */
    filter: invert(46%) sepia(5%) saturate(759%) hue-rotate(167deg) brightness(95%) contrast(86%);
}

.btn:hover .arrow-icon {
    filter: invert(100%) sepia(0%) saturate(7497%) hue-rotate(7deg) brightness(105%) contrast(101%);
}

.svg-white {
    filter: invert(100%) sepia(0%) saturate(7497%) hue-rotate(7deg) brightness(105%) contrast(101%);
}

.svg-primary {
    filter: invert(52%) sepia(66%) saturate(3134%) hue-rotate(185deg) brightness(96%) contrast(79%);
}

.fx-btn-home {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.item-remove {
    width:30px;
    height:30px;
    position:absolute;
    top:20px;
    right:10px;
}

.fx-shadow {
    box-shadow:0 0 8px 0 rgb(0 0 0 / 10%);
}



/* table */
colgroup col.dark {
    background-color: #212529;
    border-color: #32383e;
    color:#fff !important;
}

.fx-table td:first-child {
    color:#fff;
}

/* colgroup col.error {
    background-color: #f2dede;
}
colgroup col.warning {
    background-color: #fcf8e3;
}
colgroup col.info {
    background-color: #d9edf7;
}    */



/* category filters */
.fx-filter-nav-btn {
    border:1px solid transparent;
    letter-spacing: 2px;
    font-size:13px;
    background-color: #fff;
    height:50px;
    /* text-transform: uppercase; */
    
    position: relative;
    outline:none;
    /* border-bottom:none; */
}

.fx-filter-nav-btn:hover:not(.fx-filter-nav-btn-open) {
    border:1px solid #000;
}

.fx-filter-nav-btn:focus {
    outline:none;
}

.fx-filter-nav-btn:focus:not(.fx-filter-nav-btn-open) {
    outline:none;
    border:1px solid #000;
}

.fx-filter-nav-btn-open {
    border:1px solid #000;
    border-bottom:1px solid transparent;
    z-index:20;
}

.filter-option {
    cursor:pointer;
}

.filter-option:hover {
    background-color: #ebedee;
}


/* FOOTER */
.witex-footer a {
    color:#000;
}

.witex-footer li a {
    padding:6px 6px 6px 0;
    display: inline-block;
}

.tel-icon {
    margin-top:-2px;
}


.product-preview {
    position: relative;

}

.product-preview-controls {
    width: 100%;
    height:30px;
    display: flex;
    /* justify-content: space-evenly; */
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    visibility: hidden;
    flex-wrap: wrap-reverse;
}

.product-preview:hover .product-preview-controls, .product-preview:active .product-preview-controls, .product-preview:focus .product-preview-controls {
    visibility: visible;
}

.product-preview-btn {
    width: 30px;
    height:4px;
    background-color: rgba(255, 255, 255, .3);
    position: relative;
}

.product-preview-btn:not(:last-child) {
    margin-right:10px;
}

.product-preview-btn-active {
    background-color: rgba(255, 255, 255, .6);
}

.product-preview-btn-active::before {
    content:"";
    position: absolute;
    background-color: #fff;
    bottom:-1px;
    height:6px;
    width:100%;
    animation: loading 1.4s linear forwards;
    transform: scaleX(0);
    transform-origin: left;
}

.product-preview-btn-active-click::before {
    /* width: 100%; */
    transform: scaleX(1);
    animation: none;
}

.product-preview-controls:hover .product-preview-btn {
    height:12px;
}

.product-preview-controls:hover .product-preview-btn-active::before {
    height:14px;
}

@keyframes loading {
    0% {
        /* width: 0%; */
        transform: scaleX(0);
    } 100% {
        /* width: 100%; */
        transform: scaleX(1);
    }
}



.price-strike {
    font-size: 14px;
}

.price-tag {
    color:rgb(168, 33, 33);
    font-weight: 600;
    font-size:18px;
}

.price-strike + .price-tag {
    font-size:17px;
    margin-top:-6px;
}

.btn-height .btn {
    height: 100%;
}

.search-btn {
    display: block;
    background-color: #2ca860;
    color:#fff;
    border:none;
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:38px;
    height: 38px;
    cursor:pointer;
}

.search-btn:hover {
    background-color: #38c172;
}

.cart-number {
    position: absolute;
    top:-7px;
    right:-7px;
    width: 20px;
    height:20px;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2ca860;
    border-radius: 100%;
    z-index: 1;
    color:#fff;
}

.cart-number:empty {
    display:none;
}

.cart-wrapper {
    width: 100%;
    margin:0 0 10px 0;
}





.category-btn:hover .category-dropdown {
    opacity: 1;
    pointer-events: auto;
}

.category-dropdown {
    /* position: absolute; */
    /* top:100%; */
    opacity: 0;
    transition: opacity .1s linear;
}

.subcategories-panel {
    background-color: #fff;
    position: absolute;
    left:265px;
    width:calc(100% - 280px); /* -30px padding container */
    height:400px;
    top:0;
    visibility: hidden;
    z-index: -1;
    border:1px solid #2ca860;
}

.category-open-panel:hover .subcategories-panel, 
.category-open-panel:hover .first-category-link::before, 
.category-open-panel:hover .first-category-link::after {
    display: block;
    visibility: visible;
}



.first-category-link {
    position: relative;
    border-top:1px solid #fff;
    border-left:1px solid #fff;
    border-bottom:1px solid #fff;
    border-right:1px solid #fff;
}

.first-category-link:hover {
    border-top:1px solid #2ca860;
    border-left:1px solid #2ca860;
    border-bottom:1px solid #2ca860;
}

.first-category-link::before {
    content:"";
    background-color: #fff;
    height: 100%;
    width: 5px;
    position: absolute;
    right:-4px; top:0;
    display: none;
}

/* .first-category-link::after {
    content:"";
    background-color: red;
    height: 100%;
    width: 100%;
    position: absolute;
    right:-2px; top:0;
    display: none;
} */


.first-category-link:only-child:hover {
    border-right:1px solid #2ca860;
}

.first-category-link:only-child:hover::before {
    background-color: transparent;
}

.hide {
    display: none !important;
    visibility: hidden;
}




input[type="radio"] + .address-item{ 
    cursor: pointer;
}

input[type="radio"] + .address-item:hover{ 
    background-color: #fafafa;
}

input[type="radio"]:checked + .address-item{ 
    border:1px solid #2ca860 !important;
    box-shadow: 0 0 6px rgba(0,0,0,.2);
}





.my-message {
    width:90%;
}

.others-message {
    width:90%;
    margin-left:10%;
}




.fx-line {
    position: relative;
    font-size:10px;
}

.fx-line::before {
    content: "";
    position: absolute;
    left:50%;
    top:50%;
    width: calc(60% - 30px);
    height: 3px;
    background-color: #f0f0f0;
    transform: translate(-50%, -50%);
}

.fx-bg {
    background-color: #f8fafc;
}





.navbar-flex {
    flex:1;
}

.search-wrapper {
    width: 100%;
}

.product-card-title {
    width:100%;
    padding:10px 10px 0;
    text-align: center;
}

.product-card-attributes-list {
    list-style-position: inside;
}

.main {
    padding-top:50px;
}

.product-preview-link-wrapper {
    max-width: 100%;
    height: auto;
}

.category-menu {
    width:100%;
}

.category-products-container {
    width:100%;
}

.category-content-wrapper {
    flex-direction: column-reverse;
}

.category-products {
    justify-content: center;
}

.category-search-keyword {
    border:none;
    border-bottom:1px solid black;
    padding:0 15px;
    background-color:transparent;
    height:30px;
    width:100%;
}

.mobile-nav-icon {
    height: 20px;
    width: 20px;
}

.mobile-nav a {
    color:#000;
}

.col-20 {
    width: 20%;

}

.cart-box {
    width:100%;
}

.cart-content {
    width:100%;
    margin-right:0px;
}

.item-cart-image {
    width: 100px;
    height: auto;
}

.product-main-image-wrapper {
    width:100%;
}

@media (min-width: 768px) {

    .navbar-flex {
        flex:1;
    }

    .search-wrapper {
        width: 50%;
    }

    .product-card-title {
        width:calc(100% - 450px);
        padding-left:20px;
        text-align: left;
    }

    .main {
        padding-top:110px;
    }

    .product-preview-link-wrapper {
        width: 350px;
        height: 350px;
    }

    .category-menu {
        width:200px;
    }
    
    .category-products-container {
        width:1140px;
    }

    .category-products {
        justify-content: start;
    }

    .category-content-wrapper {
        flex-direction: row;
    }

    .category-search-keyword {
        width:220px;
    }

    .cart-box {
        width:400px;
    }

    .cart-content {
        width:calc(100% - 415px);
        margin-right:15px;
    }

    .item-cart-image {
        width: 150px;
        flex:auto;
        max-width: initial;
    }

    .product-main-image-wrapper {
        width:75%;
    }
    
    .fx-line {
        font-size: initial;
    }
}

@media (max-width: 768px) {
    .product-item-row .product-preview img, .product-item-row .product-preview-wrapper, .product-preview img {
        max-width: 100%;
        height: auto;
    }

    .product-item:nth-child(3n+2) {
        margin-left:0;
        margin-right:0;
    }
}


/* LG */
@media (min-width: 992px) {
    .cart-wrapper {
        width:calc(100% - 415px);
        margin-right:15px;
    }
}



/* MOBILE CATEGORIES */
.mobile-categories {
    position: fixed;
    top:0;
    left:0;
    right:0;
    background-color: #fff;
    width:100%;
    height: 100%;
    padding:80px 20px 20px 20px;
    transition: transform .3s ease;
}

.mobile-category-link, .mobile-submenu-link {
    position: relative;
    padding:10px 30px 10px 10px;
    font-size:18px;
    letter-spacing: 1px;
    color:inherit;
    text-decoration: none;
    display: block;
    border-bottom: 1px solid #dee2e6;
}

.mobile-category-link::before {
    content:"";
    position: absolute;
    top:50%;
    transform: translate(0, -50%);
    right: 15px;
    width: 15px;
    height: 15px;
    background-image: url('/images/icons/right-arrow.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.mobile-submenu {
    position:absolute;
    top:0;
    left:0;
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    transform: translate(100%);
    padding:80px 20px 20px 20px;
    transition: transform .3s ease;
    z-index: 2;
}

.mobile-menu-open {
    transform: translate(0%);
}

.btn-outline-success {
    color:#00682d;
}



/* Bootstrap modal fix */
.modal-dialog,
.modal-content {
    /* 80% of window height */
    height: 80%;
}

.recommend-modal-form {
    max-height: 100%;
}

.modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 120px);
    overflow-y: scroll;
}

.uppercase {
    text-transform: uppercase;
}

.top-banner {
    
}